<template>
  <div
    v-if="routeQueryFetched"
    v-resize="calcHeight"
    class="report"
    :style="reportStyle"
  >
    <users-report-filter
      :filter="filter"
      :filter-data="filterData"
      @submit="onFilterSubmit"
    />
    <users-report-chart ref="chart" />
    <user-report-table
      v-if="isFetched"
      :height="height"
      :filter-data="filterData"
      :limit="200"
      @page-changed="onPageCanged"
      @page-size-changed="onPageSizeCanged"
      @save-csv="onSaveCsv"
      @save-xls="onSaveXls"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'
import UsersReportFilter from './UsersReport/UsersReportFilter'
import UsersReportChart from './UsersReport/UsersReportChart'
import UserReportTable from './UsersReport/UserReportTable'
import page from '~/mixins/page'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'UsersReport',
  components: { UserReportTable, UsersReportChart, UsersReportFilter },
  data () {
    return {
      height: 500,
      routeQueryFetched: false
    }
  },
  computed: {
    ...mapGetters({
      isFetched: 'reports/usersReport/isFetched',
      deviceInfo: 'device'
    }),
    ...mapFields({
      filter: 'reports.usersReport.filter',
      filterData: 'reports.usersReport.filterData',
      page: 'reports.usersReport.page',
      pageSize: 'reports.usersReport.pageSize',
      reportCsv: 'reports.usersReport.reportCsv',
      reportXls: 'reports.usersReport.reportXls'
    }),
    reportStyle () {
      let style = ''
      if (this.deviceInfo?.type === 'mobile' || this.deviceInfo?.type === 'tablet') {
        style += 'overflow: hidden; overflow-y: auto; max-height: calc(100svh - 80px);'
      }
      return style
    }
  },
  // watch: {
  //   filter: {
  //     handler (n) {
  //     },
  //     deep: true
  //   }
  // },
  async mounted () {
    await this.parsePageQuery()
      .then(() => {
        this.$nextTick(async () => {
          await this.fetch()
        })
      })
  },
  beforeMount () {
  },
  beforeDestroy () {
  },
  methods: {
    ...page.methods,
    ...mapActions({
      fetchReport: 'reports/usersReport/fetchReport',
      fetchReportChartData: 'reports/usersReport/fetchReportChartData',
      fetchReportItemsData: 'reports/usersReport/fetchReportItemsData',
      fetchReportCsv: 'reports/usersReport/fetchReportCsv',
      fetchReportXls: 'reports/usersReport/fetchReportXls'
    }),
    async fetch () {
      if (!this.isFetched || this.page === 1) {
        await this.fetchReport()
        this.$nextTick(() => {
          this.$refs?.chart?.fillData()
        })
      } else {
        await this.fetchReportItemsData()
      }
      this.$nextTick(() => {
        this.calcHeight()
      })
    },
    async onFilterSubmit (filter) {
      if (this.routeQueryFetched) {
        this.filter = filter
        if (this.page !== 1) {
          this.page = 1
        }
        this.updatePageQuery()
        await this.fetch()
      }
    },
    async onPageCanged (page) {
      if (this.routeQueryFetched) {
        this.updatePageQuery()
        await this.fetch()
      }
    },
    async onPageSizeCanged (pageSize) {
      if (this.routeQueryFetched) {
        this.updatePageQuery()
        await this.fetch()
      }
    },
    calcHeight () {
      if (!isServer && window && this.deviceInfo?.type !== 'mobile') {
        const $navbar = document.querySelector('.navbar')
        const $tHeader = document.querySelector('.report-table .card-header')
        const $tHead = document.querySelector('.report-table .table thead')
        const $tPager = document.querySelector('.report-table .card-header')
        const navbarHeight = $navbar?.clientHeight || 0
        const tHeaderHeight = $tHeader?.clientHeight || 0
        const tHeadHeight = $tHead?.clientHeight || 0
        const tPagerHeight = $tPager?.clientHeight || 0
        const windowHeight = window.innerHeight
        this.height = windowHeight - navbarHeight - tHeaderHeight - tHeadHeight - tPagerHeight
      }
    },
    async onSaveCsv () {
      await this.fetchReportCsv()
      const k = moment().format('DDMMYYHHmmss')
      const filename = `users-report-${k}.csv`
      const file = new Blob([this.reportCsv], { type: 'text/plain;charset=utf-8' })
      this.$nextTick(() => {
        this.reportCsv = null
      })
      if (window.navigator.msSaveOrOpenBlob) {
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename)
      } else {
        // Others
        const a = document.createElement('a')
        const url = URL.createObjectURL(file)
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        this.$nextTick(() => {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        })
      }
    },
    async onSaveXls () {
      await this.fetchReportXls()
      const k = moment().format('DDMMYYHHmmss')
      const filename = `users-report-${k}.xlsx`
      const file = new Blob([this.reportXls], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      this.$nextTick(() => {
        this.reportXls = null
      })
      if (window.navigator.msSaveOrOpenBlob) {
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename)
      } else {
        // Others
        const a = document.createElement('a')
        const url = URL.createObjectURL(file)
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        this.$nextTick(() => {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        })
      }
    },
    updatePageQuery () {
      if (this.routeQueryFetched) {
        const query = {}
        if (this.page) {
          query.page = `${this.page}`
        }
        if (this.pageSize) {
          query.limit = `${this.pageSize}`
        }
        if (this.filter && Object.keys(this.filter).length) {
          const filter = Object.keys(this.filter).map((k) => {
            return {
              column: k,
              value: this.filter[k]
            }
          }).filter(i => i.value !== '')
          for (const item of filter) {
            if (typeof item.value === 'object' && item.value !== null) {
              for (const key of Object.keys(item.value)) {
                query[`filter[${item.column}][${key}]`] = item.value[key]
              }
            } else if (item.value !== null && item.value !== '') {
              query[`filter[${item.column}]`] = item.value
            }
          }
        }
        if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
          this.$router.push({
            path: `${this.$route.path}`,
            query
          })
        }
      }
    },
    parsePageQuery () {
      return new Promise((resolve, reject) => {
        if (!this.routeQueryFetched) {
          const initFilter = Object.keys(this.filter).map((k) => {
            return {
              column: k,
              value: this.filter[k]
            }
          }).filter(i => i.value !== '')

          let hasFilter = false
          const filter = {}
          const query = this.$route.query
          Object.keys(query).forEach((k) => {
            const isPage = k.match(/^page$/)
            const isPageSize = k.match(/^limit$/)
            const isFilter = k.match(/^filter\[([\w.]*)\]\[?([\w.]*)?\]?/)
            if (isFilter) {
              hasFilter = true
              const column = isFilter[1]
              const subKey = isFilter[2]
              const idx = initFilter.findIndex(i => i.column === column)
              let value
              if (isFilter[2]) {
                value = idx >= 0 ? filter[idx]?.value : {}
                value[subKey] = query[k]
              } else {
                value = query[k]
              }
              if (idx >= 0) {
                initFilter[idx] = {
                  column,
                  value
                }
              } else {
                initFilter.push({
                  column,
                  value
                })
              }
            }
            initFilter.forEach((i) => {
              filter[i.column] = i.value
            })
            if (isPage) {
              this.page = parseInt(query.page, 10) || 1
            }
            if (isPageSize) {
              this.pageSize = parseInt(query.limit, 10) || 10
            }
          })
          if (hasFilter) {
            this.filter = filter
          }
          this.$nextTick(() => {
            this.routeQueryFetched = true
            resolve()
          })
        } else {
          // already fetched
          // eslint-disable-next-line prefer-promise-reject-errors
          reject()
        }
      })
    }
  }
}
</script>

<style scoped>
.report {
  overflow-y: auto;
}
</style>
