<template>
  <b-card ref="table" class="p-0 report-table ">
    <b-card-header class="d-flex align-items-center py-3">
      <h6 class="m-0 text-dark">
        <span v-shtml="$t('rUsers_tbl_topLabel', { first: itemsFirstItem, last: itemsLastItem, total: itemsTotal })" />
        <br>
        <small>{{ $t('rUsers_tbl_legend') }}:</small>
        <b-badge variant="success">
          {{ $t('rUsers_tbl_l_paidChat') }}
        </b-badge>
        <b-badge variant="warning">
          {{ $t('rUsers_tbl_l_freeChat') }}
        </b-badge>
        <b-badge variant="secondary">
          {{ $t('rUsers_tbl_l_isNew') }}
        </b-badge>
        <b-badge variant="dark">
          {{ $t('rUsers_tbl_l_notNew') }}
        </b-badge>
      </h6>
      <h5 class="ml-auto">
        <small>Clients by type:</small>
        <b-badge variant="secondary d-inline-flex justify-content-between align-items-center">
          All:  <span class="badge badge-light ml-2">{{ (userTypeTotals && userTypeTotals.all) || 0 }}</span>
        </b-badge>
        <b-badge variant="warning d-inline-flex justify-content-between align-items-center">
          With Chat:  <span class="badge badge-light ml-2">{{ (userTypeTotals && userTypeTotals.active_in_chats) || 0 }}</span>
        </b-badge>
        <b-badge variant="success d-inline-flex justify-content-between align-items-center">
          With Payments:  <span class="badge badge-light ml-2">{{ (userTypeTotals && userTypeTotals.active_in_payments) || 0 }}</span>
        </b-badge>
      </h5>
      <div class="ml-auto">
        <h3 class="text-gray-900 my-0">
          {{ itemsExpenses }}
        </h3>
      </div>
      <b-dropdown :text="$t('eLt_export_title')" class="ml-4" size="sm">
        <b-dropdown-item @click="onSaveCsv">
          {{ $t('eLt_export_csv') }}
        </b-dropdown-item>
        <b-dropdown-item @click="onSaveXls">
          {{ $t('eLt_export_xls') }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <b-table
      :id="`dt_${dtKey}`"
      :fields="fields"
      :items="tableItems"
      :sticky-header="`${height}px`"
      class="text-nowrap"
      striped
      hover
      small
      responsive
      thead-class="bg-primary text-white text-center small"
    >
      <template
        v-for="(date, i) in dates"
        #[`head(date_${i})`]="data"
      >
        <span
          :key="date"
          v-shtml="data.label"
          class="d-block text-center"
        >{{ date }}</span>
      </template>
      <template
        v-for="(date, i) in dates"
        #[`cell(date_${i})`]="data"
      >
        <span
          :key="date"
          v-shtml="data.value"
          class="d-block text-center"
        >{{ date }}</span>
      </template>
      <template
        #[`cell(affiliate)`]="data"
      >
        <affiliate-link
          v-if="data.item._at"
          :affiliate="data.item._at"
        />
      </template>
      <template
        #[`cell(tmp_1)`]="data"
      >
        <span
          v-if="data.item && data.item.user_id"
          class="d-block text-center"
          style="width: 80px;"
        >
          <users-report-mini-chart
            :item="data.item"
          />
        </span>
      </template>
      <template
        #[`cell(tmp_2)`]="data"
      >
        <span
          v-if="data.item && data.item.user_id"
          class="d-block text-left"
          style="min-width: 180px"
        >
          <b v-if="data.item._up">
            <a
              :href="`/customers/${data.item.user_id}`"
              target="_blank"
            >
              {{ data.item._up.display_name }}
            </a>
            <!--
            <user-profile-link
              v-if="data.item.user"
              :user="data.item.user"
            >
              {{ data.item.display_name }}
            </user-profile-link>
            -->
          </b>
          <b v-else>No profile</b>
          <p style="line-height:1.3;margin-bottom:0">
            <span class="badge badge-primary">
              ID:{{ data.item.user_id }}
            </span>
            <span
              v-if="data.item._ct"
              class="badge badge-secondary"
            >
              <font-awesome-icon :icon="['fas', 'location-dot']" size="sm" />
              {{ data.item._ct.name }}
            </span><br>
            <span
              class="badge mr-1"
              :class="{
                'badge-info': (data.item.type === 'client'),
                'badge-danger': (data.item.type === 'hostess')
              }"
              :title="data.item.type"
            >
              <font-awesome-icon v-if="data.item.type === 'client'" :icon="['fas', 'person']" size="sm" />
              <font-awesome-icon v-if="data.item.type === 'hostess'" :icon="['fas', 'person-dress']" size="sm" />
            </span>
            <span
              v-if="data.item.is_deleted"
              class="badge badge-danger mr-1"
              title="Deleted"
            >
              <font-awesome-icon :icon="['fas', 'trash']" size="sm" />
            </span>
            <span
              v-if="data.item.clones"
              class="badge badge-warning"
              title="Clones"
            >
              <font-awesome-icon :icon="['fas', 'users']" size="sm" />
              {{ data.item.clones }}
            </span>
          </p>
        </span>
      </template>
      <template
        #[`cell(_dta)`]="{ item }"
      >
        <span v-shtml="item._dta" />
      </template>
      <template #cell(market)="{ item }">
        <market-flag :market="getMarketNameById(item.market_id)" />
      </template>
    </b-table>
    <div
      :id="`dt_${dtKey}_pager`"
      ref="pager"
      class="d-flex align-items-start justify-content-between"
    >
      <b-pagination
        v-if="currentPage"
        v-model="currentPage"
        class="pb-2"
        :total-rows="pager && pager.total"
        :per-page="pager && pager.per_page"
        :aria-controls="`dt_${dtKey}`"
        :disabled="busy"
      />
      <div
        class="d-inline-flex align-items-center text-nowrap"
      >
        <span class="pr-2 d-none d-md-block d-lg-block">{{ $t('eDt_pageSize') }}</span>
        <b-form-select
          v-model="pageSize"
          :options="pageSizes"
          size="sm"
        />
      </div>
      <strong v-if="pager" class="small d-none d-md-block d-lg-block">
        {{ $t('eDt_iteratorCounts', {
          from: ((pager.current_page - 1) * pager.per_page) + 1,
          to: (pager.current_page) * pager.per_page,
          total: pager.total
        }) }}
      </strong>
    </div>
  </b-card>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import UsersReportMiniChart from './UsersReportMiniChart'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'UserReportTable',
  components: { UsersReportMiniChart },
  props: {
    height: {
      type: Number,
      default: 500
    },
    filterData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dtKey: 'users_report',
      busy: false,
      thBgColor: '#007bff !important',
      thColor: '#fff !important'
    }
  },
  computed: {
    ...mapFields({
      currentPage: 'reports.usersReport.page',
      pageSize: 'reports.usersReport.pageSize',
      reportData: 'reports.usersReport.reportData'
    }),
    itemsExpenses () {
      return this.reportData?.items_expenses
    },
    items () {
      return this.reportData?.items?.data
    },
    itemsFirstItem () {
      return this.reportData?.items?.from
    },
    itemsLastItem () {
      return this.reportData?.items?.to
    },
    itemsTotal () {
      return this.reportData?.items?.total
    },
    dates () {
      return this.reportData?.dates
    },
    totals () {
      return this.reportData?.totals
    },
    unlocked () {
      return this.reportData?.unlocked
    },
    userTypeTotals () {
      return this.reportData?.user_type_totals
    },
    fields () {
      const fields = []
      if (this.dates && this.dates.length && this.totals && this.totals.length) {
        fields.push({
          key: 'tmp_1',
          label: '',
          sortable: false,
          thStyle: `width: 100px; background: ${this.thBgColor};  color: ${this.thColor};`
          // stickyColumn: true
        })
      }
      fields.push({
        key: 'tmp_2',
        label: '',
        sortable: false,
        thStyle: `width: 200px; background: ${this.thBgColor};  color: ${this.thColor};`,
        stickyColumn: true
      })
      fields.push({
        key: '_dca',
        label: this.$t('rUsers_tbl_f_created_at'),
        sortable: false,
        class: 'align-middle text-left',
        thStyle: `background: ${this.thBgColor};  color: ${this.thColor};`
      })
      fields.push({
        key: 'affiliate',
        label: this.$t('rUsers_tbl_f_affiliate'),
        sortable: false,
        class: 'align-middle text-left',
        thStyle: `background: ${this.thBgColor};  color: ${this.thColor};`
      })
      fields.push({
        key: 'utm_source',
        label: this.$t('rUsers_tbl_f_utm_source'),
        sortable: false,
        class: 'align-middle text-center',
        thStyle: `background: ${this.thBgColor};  color: ${this.thColor};`
      })
      fields.push({
        key: 'utm_campaign',
        label: this.$t('rUsers_tbl_f_utm_campaign'),
        sortable: false,
        class: 'align-middle text-center',
        thStyle: `background: ${this.thBgColor};  color: ${this.thColor};`
      })
      fields.push({
        key: 'utm_medium',
        label: this.$t('rUsers_tbl_f_utm_medium'),
        sortable: false,
        class: 'align-middle text-center',
        thStyle: `background: ${this.thBgColor};  color: ${this.thColor};`
      })
      fields.push({
        key: 'referer',
        label: this.$t('rUsers_tbl_f_referer'),
        sortable: false,
        class: 'align-middle text-center',
        thStyle: `background: ${this.thBgColor};  color: ${this.thColor};`
      })
      fields.push({
        key: 'market',
        label: this.$t('rUsers_tbl_f_market'),
        class: 'align-middle text-center',
        thStyle: `background: ${this.thBgColor};  color: ${this.thColor};`
      })
      fields.push({
        key: '_dta',
        label: this.$t('rUsers_tbl_f_total_amount'),
        sortable: false,
        class: 'align-middle text-center',
        thStyle: `background: ${this.thBgColor};  color: ${this.thColor};`
      })
      if (this.dates) {
        this.dates.forEach((v, i) => {
          if (i + 1 < this.dates.length) {
            const currDate = this.dates[i]
            const nextDate = this.dates[i + 1]
            fields.push({
              key: `date_${i}`,
              label: `<span class="badge badge-pill badge-light" style="font-size:.8rem">${i + 1}</span>
                <small class="text-white d-block mt-1" style="line-height:1.2em">${currDate}<br/>${nextDate}</small>`,
              sortable: false,
              class: 'align-middle text-center',
              thStyle: `background: ${this.thBgColor};  color: ${this.thColor};`,
              formatter: (value, key, item) => {
                let result = value
                if (!result && item) {
                  key = key.replace(/date_/, '')
                  result = ''
                  if (item._dp && item._dp[key]) {
                    result += `<b>${item._dp[key]}</b>`
                  }
                  if ((item._cf && item._cf[key]) || (item._cp && item._cp[key])) {
                    result += '<div class="text-nowrap">'
                  }
                  if (item._cp && item._cp[key]) {
                    result += `<span class="badge badge-success text-white">${item._cp[key]}</span> `
                  }
                  if (item._cf && item._cf[key]) {
                    result += `<span class="badge badge-warning text-white">${item._cf[key]}</span> `
                  }
                  if ((item._cf && item._cf[key]) || (item._cp && item._cp[key])) {
                    result += '<div>'
                  }
                }
                if (!result) {
                  result = '-'
                }
                return result
              }
            })
          }
        })
      }
      return fields
    },
    tableItems () {
      const items = [
        this.getCustomRow('euro'),
        this.getCustomRow('talk'),
        this.getCustomRow('credits')
      ]
      if (this.items && this.items.length) {
        for (const i of this.items) {
          items.push(i)
        }
      }
      return items
    },
    pager () {
      if (this.reportData?.items) {
        return {
          count: this.reportData.items.count,
          current_page: this.reportData.items.current_page,
          links: this.reportData.items.links,
          per_page: this.reportData.items.per_page,
          total: this.reportData.items.total,
          total_pages: this.reportData.items.total_pages
        }
      }
      return {
        count: 0,
        current_page: 0,
        links: [],
        per_page: 0,
        total: 0,
        total_pages: 0
      }
    },
    pageSizes () {
      const values = [
        {
          value: 10,
          text: 10
        },
        {
          value: 25,
          text: 25
        },
        {
          value: 50,
          text: 50
        },
        {
          value: 100,
          text: 100
        },
        {
          value: 200,
          text: 200
        },
        {
          value: 500,
          text: 500
        }
      ]
      const idx = values.findIndex((v) => {
        return v.value === this.pageSize
      })
      if (idx < 0) {
        values.push({
          value: this.pageSize,
          text: this.pageSize
        })
        values.sort((a, b) => {
          if (a.value < b.value) {
            return -1
          }
          if (a.value > b.value) {
            return 1
          }
          return 0
        })
      }
      return values
    }
  },
  watch: {
    currentPage (n, o) {
      if (n !== o && typeof o !== 'undefined' && !this.skipReload) {
        this.onPageChanged(n)
      }
    },
    pageSize (n, o) {
      if (n !== o && typeof o !== 'undefined') {
        this.currentPage = 1
        this.onPageSizeChanged(n)
      }
    }
  },
  mounted () {},
  methods: {
    getCustomRow (type) {
      const row = {}
      if (this.dates && this.dates.length && this.totals && this.totals.length) {
        row.tmp_1 = ''
      }
      row.tmp_2 = ''
      row.created_at = ''
      row.affiliate = ''
      row.utm_source = ''
      row.utm_campaign = ''
      row.utm_medium = ''
      row.referer = ''
      row.total_amount = ''
      switch (type) {
        case 'euro':
          if (this.totals) {
            this.totals.forEach((v, i) => {
              if (i + 1 < this.dates.length) {
                row[`date_${i}`] = v ? `<b>${v}</b>` : '-'
              }
            })
          }
          break
        case 'talk':
          if (this.unlocked) {
            this.unlocked.forEach((v, i) => {
              if (i + 1 < this.dates.length) {
                row[`date_${i}`] = v.total
                  ? `<b>${v.total} Chat</b>
                    <div class="text-nowrap">
                        <span class="badge badge-success">${v.paid}</span>
                        <span class="badge badge-warning">${v.free}</span>
                        <span class="badge badge-secondary">${v.new}</span>
                        <span class="badge badge-dark">${v.non_new}</span>
                    </div>`
                  : '-'
              }
            })
          }
          break
        case 'credits':
          if (this.unlocked) {
            this.unlocked.forEach((v, i) => {
              if (i + 1 < this.dates.length) {
                row[`date_${i}`] = v.total
                  ? `<b>${v.amount} Cred.</b>`
                  : '-'
              }
            })
          }
          break
      }
      return row
    },
    onPageChanged (page) {
      this.$emit('page-changed', page)
      if (!isServer && window) {
        const havBar = document.querySelector('.wrapper .content .navbar')?.clientHeight || 0
        const table = this.$refs.table.offsetTop || 0
        document.querySelector('.report').scrollTo({ top: table - havBar, behavior: 'smooth' })
        this.$nextTick(() => {
          const $t = document.querySelector('.report-table .table-responsive')
          $t.scrollTo({ top: 0 })
        })
      }
    },
    onPageSizeChanged (size) {
      this.$emit('page-size-changed', size)
      if (!isServer && window) {
        const havBar = document.querySelector('.wrapper .content .navbar')?.clientHeight || 0
        const table = this.$refs.table.offsetTop || 0
        document.querySelector('.report').scrollTo({ top: table - havBar, behavior: 'smooth' })
        this.$nextTick(() => {
          const $t = document.querySelector('.report-table .table-responsive')
          $t.scrollTo({ top: 0 })
        })
      }
    },
    onSaveCsv () {
      this.$emit('save-csv')
    },
    onSaveXls () {
      this.$emit('save-xls')
    },
    getMarketNameById (id) {
      if (this.filterData?.market?.length > 0) {
        const market = this.filterData.market.find(i => i.value === id)
        if (market) {
          return market.text
        }
      }
      return null
    }
  }
}
</script>

<style scoped>

</style>
