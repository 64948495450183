<template>
  <div>
    <client-only>
      <line-chart
        v-if="datacollection"
        ref="chart"
        :chart-data="datacollection"
        :options="options"
        class="mt-3 px-3"
      />
    </client-only>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'UsersReportChart',
  data () {
    return {
      datacollection: null,
      options: {
        scales: {
          y: {
            beginAtZero: true
          },
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback (value, index, values) {
                  return (value).toLocaleString('de-DE') + ' €'
                }
              }
            }
          ]
        },
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label (tooltipItem) {
              return (tooltipItem.yLabel).toLocaleString('de-DE') + ' €'
            }
          }
        },
        aspectRatio: 3,
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {
    ...mapFields({
      reportData: 'reports.usersReport.reportData',
      sidebarState: 'layout.sidebarState'
    })
  },
  watch: {
    reportData: {
      handler () {
        this.fillData()
      },
      deep: true
    },
    // Redraw chart if sidebar toggle
    sidebarState (n) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.chart.reRender()
        }, 300)
      })
    }
  },
  mounted () {
    this.fillData()
  },
  methods: {
    fillData () {
      this.datacollection = {
        labels: this.reportData.labels,
        datasets: [
          {
            label: 'Expenditure in the period',
            data: this.reportData.data,
            backgroundColor: this.reportData.bg,
            borderColor: this.reportData.border,
            borderWidth: 1
          }
        ]
      }
    }
  }
}
</script>

<style>
#line-chart {
  max-height: 400px;
}
</style>
