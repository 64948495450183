<template>
  <b-card>
    <b-row>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rUsers_filter_f_from')"
          label-size="sm"
        >
          <b-form-datepicker
            v-model="from"
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            size="sm"
            reset-button
            :max="to"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rUsers_filter_f_to')"
          label-size="sm"
        >
          <b-form-datepicker
            v-model="to"
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            size="sm"
            reset-button
            :min="from"
            :max="maxDate"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rUsers_filter_f_interval')"
          label-size="sm"
        >
          <b-form-select
            v-model="interval"
            :options="filterData.interval"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rUsers_filter_f_user_type')"
          label-size="sm"
        >
          <b-form-select
            v-model="user_type"
            :options="filterData.user_type"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rUsers_filter_f_affiliate')"
          label-size="sm"
        >
          <b-form-select
            v-model="affiliate"
            :options="filterData.affiliate"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rUsers_filter_f_source')"
          label-size="sm"
        >
          <b-form-select
            v-model="source"
            :options="filterData.source"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rUsers_filter_f_campaign')"
          label-size="sm"
        >
          <b-form-select
            v-model="campaign"
            :options="filterData.campaign"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rUsers_filter_f_medium')"
          label-size="sm"
        >
          <b-form-select
            v-model="medium"
            :options="filterData.medium"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rUsers_filter_f_market')"
          label-size="sm"
        >
          <b-form-select
            v-model="market"
            :options="filterData.market"
            size="sm"
            label-field="text"
            value-field="value"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="1">
        <b-form-group
          :label="$t('rUsers_filter_f_referer')"
          label-size="sm"
        >
          <b-form-input
            v-model="referer"
            size="sm"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex align-items-center justify-content-end">
        <b-btn variant="primary" size="sm" class="mr-2" @click="onSubmit">
          Submit
        </b-btn>
        <b-btn variant="secondary" size="sm" @click="resetFilter">
          Reset
        </b-btn>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex'
import * as moment from 'moment'
export default {
  name: 'UsersReportFilter',
  props: {
    filter: {
      type: Object,
      default: () => ({})
    },
    filterData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      maxDate: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
      from: this.filter?.from || moment().subtract(30, 'days').format('YYYY-MM-DD'),
      to: this.filter?.to || moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
      interval: this.filter?.interval || '1m',
      user_type: this.filter?.user_type || 'all',
      affiliate: this.filter?.affiliate || '',
      source: this.filter?.source || '',
      campaign: this.filter?.campaign || '',
      medium: this.filter?.medium || '',
      market: this.filter?.market || '',
      referer: this.filter?.referer || ''
    }
  },
  computed: {},
  mounted () {
    this.fetchFilterData()
  },
  methods: {
    ...mapActions({
      fetchFilterData: 'reports/usersReport/fetchFilterData',
      resetFilter: 'reports/usersReport/resetFilter'
    }),
    onSubmit () {
      moment().format('hh:mm:ss')
      this.$nextTick(() => {
        this.$emit('submit', {
          from: this.from,
          to: this.to,
          interval: this.interval,
          user_type: this.user_type,
          affiliate: this.affiliate,
          source: this.source,
          campaign: this.campaign,
          medium: this.medium,
          market: this.market,
          referer: this.referer
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
