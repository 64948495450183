<template>
  <div>
    <client-only>
      <line-chart
        v-if="datacollection"
        ref="chart"
        :chart-data="datacollection"
        :options="options"
        :styles="{height: '60px', width: '80px'}"
      />
    </client-only>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'UsersReportMiniChart',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      datacollection: null,
      options: {
        scales: {
          y: {
            beginAtZero: true
          },
          xAxes: [
            {
              display: false,
              ticks: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              display: false,
              ticks: {
                beginAtZero: true,
                display: false
              }
            }
          ]
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 0
          }
        },
        animation: false
      }
    }
  },
  computed: {
    ...mapFields({
      reportData: 'reports.usersReport.reportData',
      sidebarState: 'layout.sidebarState'
    }),
    dates () {
      return this.reportData && this.reportData.dates
    }
  },
  watch: {
    reportData: {
      handler () {
        this.fillData()
      },
      deep: true
    },
    // Redraw chart if sidebar toggle
    sidebarState (n) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.chart.reRender()
        }, 300)
      })
    }
  },
  mounted () {
    this.fillData()
  },
  methods: {
    fillData () {
      const data = []
      const backgroundColor = []
      const borderColor = []
      const labels = []
      if (this.dates && this.dates.length) {
        if (this.dates.length < 10) {
          this.dates.forEach((v, i) => {
            if (i + 1 < this.dates.length) {
              data.push(
                this.item._p[i] ? this.item._p[i] / 100 : 0
              )
              backgroundColor.push('rgba(255, 99, 132, 0.2)')
              borderColor.push('rgba(255, 99, 132, 1)')
              labels.push('')
            }
          })
        } else {
          for (let i = 0; i <= 10; i++) {
            data.push(
              this.item._p[i] ? this.item._p[i] / 100 : 0
            )
            backgroundColor.push('rgba(255, 99, 132, 0.2)')
            borderColor.push('rgba(255, 99, 132, 1)')
            labels.push('')
          }
        }
      }
      this.datacollection = {
        labels,
        datasets: [
          {
            label: 'Expenditure in the period',
            data,
            backgroundColor,
            borderColor,
            borderWidth: 1
          }
        ]
      }
    }
  }
}
</script>

<style>
#line-chart {
  max-height: 400px;
}
</style>
